.date-picker {
	margin-top: 10px;
	margin-bottom: 20px;
}

.export-tabs {
	padding-top: 30px;
}

.ant-tabs-bar {
	border-bottom: none;
}

.ant-btn-primary {
	margin-right: 15px;
}

.wrap {
	width: 50%;
}

.file-exports {
	width: 100%;
	padding-top: 20px;
}

.royalty-table .ant-table-body {
	overflow-x: auto;
}

.worklog-table .ant-table-body {
	overflow-x: auto;
}

.workedtimetoday-container,
.file-export-worked-time-summary {
	padding: 8px;
	border: 1px solid #e8e8e8;
	margin: 20px 0 20px;
	max-width: 800px;
}

.file-export-worked-time-summary-container {
	margin-top: 20px;
	max-width: 800px;
}

.file-export-worked-time-summary {
	margin: 0;
	margin-top: 10px;
}

.workedtimetoday-fetched {
	display: block;
	color: #aaa;
	font-size: 11px;
}
