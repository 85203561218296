.search-results-container {
  border: 1px solid #dfdfdf;
  padding: 8px 0 0;
  border-radius: 0 0 5px 5px;
  background: #fff;
}

.search-result--article-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.search-result--article-list li {
  list-style: none;
}

 .search-result-category-heading {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
  margin: 0 0 5px 8px;
 }

.search-result-item-link {
  display: block;
  box-sizing: border-box;
  padding: 8px 4px 8px 8px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: hidden;
}

.search-result-item-link:hover {
  background: #e6f7ff;
}

.search-result-item-heading {
  color: #40a9ff;
  font-weight: bold;
  padding-right: 7px;
  box-sizing: border-box;
  display: inline-block;
  white-space: nowrap;
  margin: 0;
}

.search-result-item-content {
  color: #ccc;
  overflow-x: hidden;
  white-space: nowrap;
  pointer-events: none;
}

.search-result-item-content * {
  font-size: 14px;
  line-height: 1em;
  color: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
  pointer-events: none;
}

.search-result-item-content table, 
.search-result-item-content img,
.search-result-item-content br {
  display: none;
}

.search-result-no-result {
  padding: 8px 4px 8px 8px;
}
