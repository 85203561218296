
.search-container {
  position: relative;
  width: 100%;
  display: block;
}

.search-form-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.search-results {
  position: absolute;
  top: 31px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 10;
}